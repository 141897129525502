import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser,
    btnSetColor_CBack} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Footer from './footer';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Product_Listing from '../sample_comp/product_listing';
import AOS from 'aos';
/* components */
import Top_Banner from '../sample_comp/top_banner';
import Features_Slider  from '../sample_comp/features_slider';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import './contact_us.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products=""
class Contact_Us extends React.Component{
    constructor(props){
        super(props);
        loc=props.location["pathname"].substr(1);
        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          
          //window.location.href="./Dashboard";
        }
      }
    componentDidMount(){
        clsObject=this;
  //alert("testing=="+data);
  
   var pgMap=[];
   pgMap.push({name:"curl_type",value:"getPgData"});
   pgMap.push({name:"res_func",value:"callBackpgData"});
   pgMap.push({name:"loc",value:loc});
  // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
   //mapCat.push({name:"emp_id",value:userObject[0].id});
   pgMap["res_func"]="callBackpgData";
   pgMap["curl_type"]="getPgData";
   CallCMSMapMethod(clsObject,pgMap);
  //CallMapMethod(clsObject,mapCat);
  AjaxCallMethod(this,"okSuccCNOT");
    }
    callBackpgData(data,msg){
       // alert("testing of2 "+data);
        //$("#pgComp").html(data);
        var listObj= JSON.parse("{\"access\":"+data+"}");
        //alert("successfully parse");
        var mainHt="";
        if(loc==="home"){
           // mainHt+=Video_Banner_Comp();
        }
        var injextI=0;
        var injCondition=false;
      
        for(var _dlist=0;_dlist<50;_dlist++){
         
            if(listObj.access[_dlist]){
                if(listObj.access[_dlist].top_banner){
                   // alert("I am in conditio");
                   injCondition=true;
                   mainHt+=Top_Banner(listObj.access[_dlist].top_banner);
                }
                else if(listObj.access[_dlist].home_banner){
                   // alert("i am in cond..");
                   injCondition=true;
                    mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
                }else if(listObj.access[_dlist].dual_text_card){
                    injCondition=true;
                    //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
                   // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
                }else if(listObj.access[_dlist].single_img_text){
                    injCondition=true;
                    mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
                }else if(listObj.access[_dlist].img_text_desc_img_left){
                    injCondition=true;
                    mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
                }else if(listObj.access[_dlist].text_description){
                    injCondition=true;
                    //alert("i am in text");
                    //alert(Txt_Description(listObj.access[_dlist].text_description));
                    mainHt+=Txt_Description(listObj.access[_dlist].text_description);
                }else if(listObj.access[_dlist].list_slider){
                    injCondition=true;
                    mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
                }
                if(injCondition){
                    injextI++;
                }
                //if(injextI==1)
                {
                    //alert("check in condi == "+loc)
                    //if(loc==="home"){
                       // mainHt+=Features_Slider();
                        //alert(products);
                       // mainHt+=products;
                   // }
               }
            }
    
          
            //$("#pgComp").innerHtml=mainHt;
        }
        if(listObj.access.length==0){
           // mainHt+=products;
        }
        //alert(mainHt);
        //mainHt+=Product_Hori_Slider();
        $("#pgComp").html(mainHt);
        RespBkMthdMapp(clsObject,"_addToCart","setCartBk");
        AOS.init();
        AOS.init({
          once: true
        });
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
          //this.forceUpdateHandler();
      
    }
    setCartBk(refId){
        // alert(refId);
         this.setState(state =>({viewData:refId}));
         this.forceUpdate();
         $("#offcanvas-cart").addClass("offcanvas-open");
       }
    callBackProList(data,msg){
    //alert("testing=="+data);
      if(data!=="0"){
       //var resPs= Product_Slider(data);
       var resPs=Product_Listing(data);
       //alert(resPs);
       products+=resPs;
      //$("#pgComp").append(resPs);
      }
      var pgMap=[];
      pgMap.push({name:"curl_type",value:"getPgData"});
      pgMap.push({name:"res_func",value:"callBackpgData"});
      pgMap.push({name:"loc",value:loc});
     // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
      //mapCat.push({name:"emp_id",value:userObject[0].id});
      pgMap["res_func"]="callBackpgData";
      pgMap["curl_type"]="getPgData";
      CallCMSMapMethod(clsObject,pgMap);
    }
    addContactReq(){
        btnSetColor_CBack(this,"contact-form","addContactRequest",function(data,msg){
            if(data!=="0"){
                alert(msg);
                window.location.reload();
            }
        })
    }
    
    render(){
        return(<div>
             <Front_Header/>
             <div id="pgComp">
           </div>
           <div className="container-sm container-fluid px-1 py-5 mx-auto" >
        <div className="row d-flex justify-content-center">
        <div className="col-xl-12 col-lg-12 col-md-10 text-center" style={{"margin-top":"70px"}}>
                {/* <div className="col-lg-4 col-md-5 mb-lm-60px col-sm-12 col-xs-12 w-sm-100">
                    <div className="contact-info-wrap">
                        <h2 className="title" >Contact Us</h2>
                        <div className="single-contact-info" >
                            <div className="contact-info-inner">
                                <span className="sub-title">OFFICE</span>
                            </div>
                            <div className="contact-info-dec">
                                <p>SA-2, 1st Floor,<br></br>Dawa Bazaar Freeganj,<br></br>Ujjain MP - 456010</p>
                            </div>
                        </div>
                        <div className="single-contact-info" >
                            <div className="contact-info-inner">
                                <span className="sub-title">CONTACTS</span>
                            </div>
                            <div className="contact-info-dec">
                                <p><a href="tel:911808890114">+91 1800-889-0114</a></p>
                            </div>
                        </div>
                        <div className="single-contact-info" >
                            <div className="contact-info-inner">
                                <span className="sub-title">EMAIL</span>
                            </div>
                            <div className="contact-info-dec">
                            <p><a href="mailto://info@mbdhwellness.com">info@mbdhwellness.com</a></p>
                            </div>
                        </div>
                       
                    </div>
                </div> */}
                <div className="card dia_cart border-box-3">
                    <div className="contact-form11">
                        <div className="heading11 contact-title mb-30 pb-70px">
                            <h2 className="title float-left">Write to Us</h2>
                        </div>
                        <form className="contact-form-style form-control11 " name="contact-form" id="contact-form">
                        <input type="hidden" name="curl_type" id="curl_type" value="addContactRequest" />  
                <input type="hidden" name="m_type" id="m_type" value="addContactRequest" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCont" />
                            <div className="row">
                                <div className="col-lg-6 mb-2 pl-2 pr-2">
                                <div className="form-group">
                                <label className="form-control-label px-3 text-left">Name *</label>
                                    <input className="required form-control" name="_name" id="_name" placeholder="" type="text" />
                                </div>
                                </div>
                                <div className="col-lg-6 mb-2 pl-2 pr-2">
                                <div className="form-group">
                                <label className="form-control-label px-3 text-left">Email *</label>
                                    <input className="required form-control" name="email" id="email" placeholder="Email*" type="email" />
                                </div>    
                                </div>
                                {/* <div className="col-lg-12" >
                                    <input name="subject" placeholder="Subject*" type="text" />
                                </div> */}
                              
                                <div className="col-lg-12 mb-2 pl-2 pr-2" >
                                    <div className="form-group">
                                    <label className="form-control-label px-3 text-left">Message *</label>
                                    <textarea className="required form-control  mb-2 pl-2 pr-2" id="message" name="message" placeholder="Your Message*"></textarea>
                                    </div>
                                    <p>
                                We would like to keep you updated with news and information on our latest products & services.*
                                </p>
                            
                                {/* <p> <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
<label for="vehicle1">Yes, please contact me and keep me up to date.</label></p>
<p>*If left blank we will not contact you.</p> */}
                                    <button onClick={()=>this.addContactReq()} className="btn btn-primary btn-hover-dark _btnSetColor111 mt-4 btn-1"  type="button">SEND</button>
                                <p style={{paddingTop:"20px"}}>
                                We will not share your information with 3rd parties.You can contact us to stop receiving updates at any time in the future by emailing info@societyofglobalcycle.com. You can also click on the unsubscribe button at the bottom of every marketing message. View our <a href="/terms_condition">Terms & Conditions</a> and our<a href="/privacy_policy">Privacy Policy</a>.
                                </p>
                                </div>
                            </div>
                        </form>
                        <p className="form-messege"></p>
                    </div>
                </div>
            </div>
        </div>
        </div>
     <Footer/>
        </div>);
    }
}
export default Contact_Us; 